type Options = {
  lang?: string
  weekday?: 'short' | 'long'
}

export function getDayName(
  dateIso: string,
  { lang, weekday }: Options = {
    lang: 'en',
    weekday: 'long'
  }
) {
  const date = new Date(dateIso)
  return date.toLocaleDateString(lang, { weekday })
}
